<template>
	<!-- 保险信息 start -->
	<div class="ant-descriptions ant-descriptions-bordered">
		<span class="row">
			<div class="ant-descriptions-title">{{ $t('table.applyInfo.loanApplyLabel.insurance') }}</div>
			<a-button
				v-if="isShowDisbursementRecords && insuranceData[tabActiveKey].hasInsurance"
				type="primary"
				style="margin-left: 10px"
				:disabled="!hasViewPermission()"
				@click="toIpfDisbursementRecords"
			>
				Disbursement Records
			</a-button>
		</span>
		<a-tabs v-model="tabActiveKey" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane v-for="(insurInfo, index) in insuranceData" :key="String(index)">
				<template slot="tab">
					{{ 'IPF ' + (insuranceData.length - index) }}
					<span v-if="insurInfo.newInput" class="new-tip">new</span>
				</template>
				<template v-if="isEditable">
					<a-descriptions bordered size="default">
						<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">
							<a-radio-group v-model="insurInfo.hasInsurance" style="margin: 10px 0" :disabled="!editInsInfo">
								<a-radio :value="1"> Yes </a-radio>
								<a-radio :value="0"> No </a-radio>
							</a-radio-group>
							<a-form class="insurance-form" :label-col="{ span: 6 }" :wrapper-col="{ span: 6 }" v-if="insurInfo.hasInsurance === 1">
								<a-form-item label="Principal Amount">
									<a-input-number :precision="2" v-model="insurInfo.insuranceAmount" :min="0" @change="getInsTryCalculate" :disabled="!editInsInfo" />
								</a-form-item>
								<a-form-item label="Interest">
									<a-input-number
										:min="0"
										:max="100"
										:formatter="(value) => `${value}%`"
										:parser="(value) => value.replace('%', '')"
										:precision="1"
										v-model="insurInfo.insuranceInterest"
										:disabled="!editInsInfo"
										@change="getInsTryCalculate"
									/>
								</a-form-item>
								<a-form-item label="Period">
									<a-input-number
										:min="0"
										:step="1"
										:precision="0"
										v-model="insurInfo.insuranceInstallments"
										@change="getInsTryCalculate"
										:disabled="!editInsInfo"
									/>
								</a-form-item>
								<a-form-item label="Installment Principal">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Installment Interest">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Installment from 2nd month">
									<a-statistic
										:precision="2"
										:value="
											insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
												? ''
												: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
										"
									/>
								</a-form-item>
							</a-form>
							<a-button v-if="!editInsInfo" type="primary" @click="handleEditInfo(true)"> {{ $t('page.edit') }} </a-button>
							<a-button v-if="editInsInfo" type="primary" @click="handleSubmit"> {{ $t('operate.save') }} </a-button>
							<a-button v-if="editInsInfo" type="primary" @click="handleEditInfo(false)" style="margin-left: 20px">
								{{ $t('operate.cancel') }}
							</a-button>
						</a-descriptions-item>
					</a-descriptions>
					<a-descriptions
						v-if="insurInfo.supplementFiles"
						class="table-descriptions"
						:title="$t('table.applyInfo.applicationLabel.supDoc')"
						size="default"
					>
						<a-descriptions-item>
							<a-table
								style="font-size: 12px"
								:columns="supCloums"
								size="default"
								:rowKey="(record, index) => index"
								:dataSource="insurInfo.supplementFiles"
								:pagination="false"
								bordered
							>
								<span slot="file" slot-scope="obj">
									<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
										!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
									}}</a>
								</span>
							</a-table>
						</a-descriptions-item>
					</a-descriptions>
				</template>
				<template v-else>
					<a-descriptions bordered v-if="insurInfo.hasInsurance === 0 || insurInfo.hasInsurance === null">
						<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">No</a-descriptions-item>
					</a-descriptions>
					<div v-else class="ipf-yes-descriptions">
						<div class="row-center ant-descriptions-bordered ipf-wrapper">IPF</div>
						<a-descriptions bordered :column="2">
							<a-descriptions-item label="Principal Amount">
								<a-statistic :precision="2" :value="insurInfo.insuranceAmount == null ? '' : insurInfo.insuranceAmount" />
							</a-descriptions-item>
							<a-descriptions-item label="Installment Principal">
								<a-statistic
									:precision="2"
									:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
								/>
							</a-descriptions-item>
							<a-descriptions-item label="Interest">
								<a-statistic :precision="1" :value="insurInfo.insuranceInterest" suffix="%" />
							</a-descriptions-item>
							<a-descriptions-item label="Installment Interest">
								<a-statistic
									:precision="2"
									:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
								/>
							</a-descriptions-item>
							<a-descriptions-item label="Period">
								{{ insurInfo.insuranceInstallments }}
							</a-descriptions-item>
							<a-descriptions-item label="Installment from 2nd month">
								<a-statistic
									:precision="2"
									:value="
										insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
											? ''
											: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
									"
								/>
							</a-descriptions-item>
							<a-descriptions-item v-if="isShowDisbursementRecords && index === 0" label="Disbursed Amount">
								<a-statistic :precision="2" :value="disbursedAmount" />
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<a-descriptions
						v-if="insurInfo.supplementFiles"
						class="table-descriptions"
						:title="$t('table.applyInfo.applicationLabel.supDoc')"
						size="default"
					>
						<a-descriptions-item>
							<a-table
								style="font-size: 12px"
								:columns="supCloums"
								size="default"
								:rowKey="(record, index) => index"
								:dataSource="insurInfo.supplementFiles"
								:pagination="false"
								bordered
							>
								<span slot="file" slot-scope="obj">
									<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
										!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
									}}</a>
								</span>
							</a-table>
						</a-descriptions-item>
					</a-descriptions>
				</template>
			</a-tab-pane>
		</a-tabs>
	</div>
	<!-- 保险信息 end -->
</template>
<script>
import { applyConfirmInsuranceInfo, insTryCalculate } from '@/api/loan'
import { apiApplyConfirmInsuranceInfo, apiIpfTryCalculate } from '@/api/insuranceRenewal'
import { getDisbursedAmount } from '@/api/loan'

export default {
	props: ['detailData', 'insuranceData', 'isEditable', 'statusCode'],
	data() {
		return {
			oldInsuranceData: {},
			editInsInfo: false,
			tabActiveKey: '0',
			isShowDisbursementRecords: ['menus.loanDataMenu.pendingDetail', 'menus.insuranceRenewalMenu.renewalDetail'].includes(this.$route.name) && ['/home/loandata/pendinglist', '/home/insuranceRenewal/renewalPending'].includes(this.$route.query.from),
			disbursedAmount: ''
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		}
	},
	watch: {
		detailData: {
			handler: async function (newValue) {
				if (newValue?.id && this.isShowDisbursementRecords && this.insuranceData[0]?.hasInsurance) {
					this.fetchDisbursedAmount()
				}
			},
			immediate: true
		}
	},
	methods: {
		async fetchDisbursedAmount() {
			const params = {
				loanId: this.detailData.id,
				loanType: this.detailData.loanType,
				disburseLoanType: 8
			}
			this.disbursedAmount = await getDisbursedAmount(params)
		},
		getInsTryCalculate() {
			if (!this.insuranceData[0].insuranceAmount) {
				return false
			}
			this.statusCode === 'ipf_info_4'
				? apiIpfTryCalculate({
						id: this.insuranceData[0].id,
						loanId: this.insuranceData[0].loanId,
						hasInsurance: this.insuranceData[0].hasInsurance,
						insuranceAmount: this.insuranceData[0].insuranceAmount,
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100,
						insuranceInstallments: this.insuranceData[0].insuranceInstallments
				  }).then((res) => {
						this.insuranceData[0].insuranceInstallmentPrincipal = res.insuranceInstallmentPrincipal
						this.insuranceData[0].insuranceInstallmentInterest = res.insuranceInstallmentInterest
				  })
				: insTryCalculate({
						insuranceAmount: this.insuranceData[0].insuranceAmount,
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100,
						insuranceInstallments: this.insuranceData[0].insuranceInstallments
				  }).then((res) => {
						this.insuranceData[0].insuranceInstallmentPrincipal = res.insuranceInstallmentPrincipal
						this.insuranceData[0].insuranceInstallmentInterest = res.insuranceInstallmentInterest
				  })
		},
		handleEditInfo(bool) {
			if (bool === false) {
				Object.keys(this.insuranceData[0]).forEach((item) => {
					this.insuranceData[0][item] = JSON.parse(this.oldInsuranceData)[item]
				})
			} else if (bool === true) {
				this.oldInsuranceData = JSON.stringify(this.insuranceData[0])
				if (!this.insuranceData[0].insuranceInterest) {
					this.insuranceData[0].insuranceInterest = 4
				}
				if (!this.insuranceData[0].insuranceInstallments) {
					this.insuranceData[0].insuranceInstallments = 3
				}
			}
			this.editInsInfo = bool
		},
		handleSubmit() {
			if (this.insuranceData[0].hasInsurance === 1) {
				if (this.$Util.isEmpty(this.insuranceData[0].insuranceAmount)) {
					this.$message.error('Principal Amount is required')
					return false
				} else if (this.$Util.isEmpty(this.insuranceData[0].insuranceInterest)) {
					this.$message.error('Interest is required')
					return false
				} else if (this.$Util.isEmpty(this.insuranceData[0].insuranceInstallments)) {
					this.$message.error('Period is required')
					return false
				}
			}
			this.statusCode === 'ipf_info_4'
				? apiApplyConfirmInsuranceInfo({
						id: this.insuranceData[0].id,
						loanId: this.insuranceData[0].loanId,
						hasInsurance: this.insuranceData[0].hasInsurance, //是否有保险
						insuranceAmount: this.insuranceData[0].insuranceAmount, //保险本金
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100, //保险利率
						insuranceInstallments: this.insuranceData[0].insuranceInstallments //保险分期
				  }).then(() => {
						this.editInsInfo = false
						this.$message.success('Success')
						this.$emit('setInsuranceCompleted', true)
				  })
				: applyConfirmInsuranceInfo({
						loanId: this.insuranceData[0].loanId,
						hasInsurance: this.insuranceData[0].hasInsurance, //是否有保险
						insuranceAmount: this.insuranceData[0].insuranceAmount, //保险本金
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100, //保险利率
						insuranceInstallments: this.insuranceData[0].insuranceInstallments //保险分期
				  }).then(() => {
						this.editInsInfo = false
						this.$message.success('Success')
						this.$emit('setInsuranceCompleted', true)
				  })
		},
		toIpfDisbursementRecords() {
			this.$router.push({
				path: 'ipfDisbursementRecords',
				query: {
					loanId: this.detailData.id,
					loanType: this.detailData.loanType,
					disburseType: this.detailData.loanInfo.disburseType || 'dtb',
					disburseLoanType: 8
				}
			})
		},
		hasViewPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.pendingDetail':
					return this.$hasPermission('ROLE_PENGING_LOAN_VIEW')
				case 'menus.insuranceRenewalMenu.renewalDetail':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_VIEW')
				case 'menus.emergencyLoanMenu.detail':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_VIEW')
				default:
					return false
			}
		}
	}
}
</script>
<style lang="less">
.ipf-yes-descriptions {
	display: flex;

	.ipf-wrapper {
		margin: 20px 0;
		width: 200px;
		border: 1px solid #e8e8e8;
		border-right: 0;
		border-radius: 6px 0 0 6px;
	}

	.ant-descriptions-view {
		border-radius: 0px 6px 6px 0px;
	}
}
.new-tip {
	position: relative;
	top: -10px;
	right: -17px;
	color: #fff;
	background: rgba(255, 0, 0, 0.6);
	padding: 2px 6px;
	border-radius: 5px;
}

.table-descriptions {
	.ant-descriptions-row {
		border-bottom: none;
		padding: 0;
	}
	.ant-descriptions-item-label {
		display: none;
	}
	.ant-descriptions-item-content {
		width: 100%;
		padding: 0;
	}
	.ant-table table {
		width: -webkit-fill-available;
	}
	.ant-descriptions-view {
		border: none;
	}
}
</style>
